import React from "react";
import { Link, useHistory } from "react-router-dom";

const Breadcrumb = ({ title, page, pageLink, subPage, goBack = false }) => {
  const history = useHistory();
  return (
    <div className="row page-titles mb-3">
      <div className="col-md-12 col-12 align-self-center">
        <div className="d-flex justify-content-between">
          <h3 className="text-themecolor">{title || "Dashboard"}</h3>
          {goBack && (
            <button
              className="btn btn-info"
              onClick={() => {
                history.goBack();
              }}
            >
              <i className="fa fa-arrow-left"></i> Go Back
            </button>
          )}
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">HOME</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={pageLink}>{page || "Dashboard"}</Link>
          </li>
          {subPage ? (
            <li className="breadcrumb-item active">{subPage || "Dashboard"}</li>
          ) : (
            ""
          )}
        </ol>
      </div>
    </div>
  );
};

export default Breadcrumb;
