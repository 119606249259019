import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { useHistory } from "react-router-dom";
import Config from "../../../config/Config";
import { storage } from "../../../../firebase/FirebaseConfig";
import Breadcrumb from "../../components/Breadcrumb";

const AddPostCategory = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [isAddLaoded, setIsAddLaoded] = useState(true);
  const [uploaded, setUploaded] = useState(true);

  const [isAdded, setIsAdded] = useState(false);

  const [data, setData] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    slug: "",
  });

  const titleChangeHandler = (evt) => {
    const value = evt.target.value;
    setFormData({
      ...formData,
      slug: value.toLowerCase().replace(/\s+/g, "-"),
      name: value,
    });
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLaoded(false);
    evt.preventDefault();

    if (progress > 0 && progress < 100) {
      M.toast({ html: "Wait for Image uploading", classes: "bg-warning" });
      return;
    }

    fetch(Config.SERVER_URL + "/categories", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAddLaoded(true);

          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            setIsAdded(!isAdded);
            setProgress(0);
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          setIsAddLaoded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}

        <Breadcrumb
          title="BLOG POST CATEGORY"
          page="BLOG POST CATEGORY"
          pageLink={"/blogPostCategories"}
          subPage={"ADD"}
          goBack={true}
        />

        {/* Add Flavour Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Flavour Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>CATEGORY DETAILS</h3>
                </div>

                {/* CATEGORY NAME */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>CATEGORY NAME</label>
                  <input
                    type="text"
                    onChange={titleChangeHandler}
                    value={formData.name}
                    className="form-control"
                    placeholder={"Technology"}
                  />
                </div>
                {/* CATEGORY SLUG */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>CATEGORY SLUG</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        slug: evt.target.value,
                      })
                    }
                    value={formData.slug}
                    className="form-control"
                    placeholder={"technology"}
                  />
                </div>

                {/* DESCRIPTION */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>DESCRIPTION</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        description: evt.target.value,
                      })
                    }
                    value={formData.description}
                    className="form-control"
                    placeholder={"Description"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button className="btn btn-info rounded" type={"submit"}>
                    {isAddLaoded ? (
                      <div>
                        <i className="fas fa-plus"></i> Add
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>

                  <button
                    className="btn btn-secondary rounded ml-2"
                    data-dismiss="modal"
                    id={"closeAddModalButton"}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPostCategory;
