import React, { useState, useEffect } from "react";
import M from "materialize-css";
import { useHistory, useParams } from "react-router-dom";
import Config from "../../../config/Config";
import Select from "react-select";
import Breadcrumb from "../../components/Breadcrumb";

const EditChildCategory = () => {
  const history = useHistory();
  const { id } = useParams();
  const [progress, setProgress] = useState(0);
  const [isAddLaoded, setIsAddLaoded] = useState(true);
  const [allParentCategory, setAllParentCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    slug: "",
    parCatId: {},
    subCatId: {},
    seoTitle: "",
    seoDescription: "",
    status: true,
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLaoded(false);
    evt.preventDefault();

    const updateData = {
      name: formData.name,
      slug: formData.slug,
      parCatId: formData.parCatId.value,
      subCatId: formData.subCatId.value,
      seoTitle: formData.seoTitle,
      seoDescription: formData.seoDescription,
      status: formData.status,
    };

    if (progress > 0 && progress < 100) {
      M.toast({ html: "Wait for Image uploading", classes: "bg-warning" });
      return;
    }

    fetch(Config.SERVER_URL + "/childCategories/" + id, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsAddLaoded(true);

          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });

            setIsAdded(!isAdded);
            setProgress(0);
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
          }
        },
        (error) => {
          setIsAddLaoded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // Get All Parent Category
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/parentCategories?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status == 200) {
            const catData = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setAllParentCategory(catData);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // Get All Sub Category
  useEffect(() => {
    let url = `${Config.SERVER_URL}/subCategories?skip=0&limit=0`;
    if (formData.parCatId.value) {
      url = `${Config.SERVER_URL}/subCategories?skip=0&limit=0&parCatId=${formData.parCatId.value}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status == 200) {
            const catData = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setAllSubCategory(catData);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [formData.parCatId]);

  // Get Child Category
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/childCategories/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status == 200) {
            setFormData({
              ...result.body,
              parCatId: {
                label: result.body.parCatId.name,
                value: result.body.parCatId._id,
              },
              subCatId: {
                label: result.body.subCatId.name,
                value: result.body.subCatId._id,
              },
            });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);
  return (
    <div className="page-wrapper px-0 pt-0">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb
          title="CHILD CATEGORIES"
          page="CHILD CATEGORY"
          pageLink={"/childCategories"}
          subPage={"EDIT"}
          goBack={true}
        />

        {/* Add Flavour Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Flavour Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>CATEGORY DETAILS</h3>
                </div>

                {/* CATEGORY NAME */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>CATEGORY NAME</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        name: evt.target.value,
                      })
                    }
                    value={formData.name}
                    className="form-control"
                    placeholder={"CUTLERY"}
                  />
                </div>
                {/* CATEGORY SLUG */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>CATEGORY SLUG</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        slug: evt.target.value,
                      })
                    }
                    value={formData.slug}
                    className="form-control"
                    placeholder={"cutlery"}
                  />
                </div>

                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CATEGORY STATUS !
                  </label>
                  <select
                    name=""
                    id=""
                    value={formData.status}
                    onChange={(evt) => {
                      setFormData({ ...formData, status: evt.target.value });
                    }}
                    className="form-control"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Disabled</option>
                  </select>
                </div>

                {/* SELECT PARENT CATEGORY */}
                <div className={"form-group mb-3 col-md-6 overflow-none"}>
                  <label className={"text-dark h6"}>
                    SELECT PARENT CATEGORY
                  </label>
                  <Select
                    value={formData.parCatId}
                    options={allParentCategory}
                    onChange={(evt) => {
                      setFormData({ ...formData, parCatId: evt, subCatId: {} });
                    }}
                  />
                </div>

                {/* SELECT SUB CATEGORY */}
                <div className={"form-group mb-3 col-md-6 overflow-none"}>
                  <label className={"text-dark h6"}>SELECT SUB CATEGORY</label>
                  <Select
                    value={formData.subCatId}
                    options={allSubCategory}
                    onChange={(evt) => {
                      setFormData({ ...formData, subCatId: evt });
                    }}
                  />
                </div>

                {/* DESCRIPTIONS */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>DESCRIPTIONS</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        description: evt.target.value,
                      })
                    }
                    value={formData.description}
                    className="form-control"
                    placeholder={"Description Here"}
                  />
                </div>

                {/* CATEGORY IMAGE */}
                {/* <div className={"form-group mb-3 col-md-6"}>
                  <div className="row">
                    <div
                      className={
                        formData.image !== "null" ? "col-md-8" : "col-md-12"
                      }
                    >
                      <label className={"text-dark h6"}>CATEGORY IMAGE</label>
                      <input
                        type="file"
                        name=""
                        className="form-control"
                        onChange={(e) => imgChangeHandler(e, "ADD")}
                      />
                    </div>

                    {!uploaded ? (
                      <div className="col-md-4">
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    ) : (
                      <div className="col-md-4">
                        {formData.image !== "null" ? (
                          <img
                            style={{
                              height: "100px",
                              width: "100px",
                              borderRadius: "20px",
                            }}
                            className="img img-thumbnail"
                            src={formData.image}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-md-12">
                  <h6 className="text-left">
                    <button className="btn btn-info shadow-none">OR</button>
                  </h6>
                </div> */}

                {/* CATEGORY IMAGE URL */}
                {/* <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>CATEGORY IMAGE URL</label>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder="CATEGORY IMAGE URL"
                    onChange={(e) =>
                      setFormData({ ...formData, image: e.target.value })
                    }
                  />
                </div> */}

                {/* SEO DETAILS */}
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>CATEGORY DETAILS</h3>
                </div>

                {/* SEO TITLE */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SEO TITLE</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        seoTitle: evt.target.value,
                      })
                    }
                    value={formData.seoTitle}
                    className="form-control"
                    placeholder={"Write SEO Title"}
                  />
                </div>
                {/* SEO Description */}
                <div className={"form-group mb-3 col-md-6"}>
                  <label className={"text-dark h6"}>SEO Description</label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        seoDescription: evt.target.value,
                      })
                    }
                    value={formData.seoDescription}
                    className="form-control"
                    placeholder={"Write SEO Description"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button className="btn btn-info rounded" type={"submit"}>
                    {isAddLaoded ? (
                      <div>
                        <i className="fas fa-edit"></i> Update
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>

                  <button
                    className="btn btn-secondary rounded ml-2"
                    data-dismiss="modal"
                    id={"closeAddModalButton"}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditChildCategory;
